import { companiesStore } from '@entities/companies-table';
import { contactsStore } from '@entities/contact-table';
import { adminService } from '@shared/api/services/admin-service';
import { IGetResourceParams } from '@shared/api/services/admin-service/admin.interfaces';
import { companiesService } from '@shared/api/services/company-service';
import {
  DataProvider,
  DeleteResult,
  GetListParams,
  GetListResult,
  GetManyReferenceResult,
  GetManyResult,
  GetOneResult,
  UpdateResult,
} from 'react-admin';

import { serializeContact } from '../libs';

export const dataProvider: DataProvider = {
  getList: async (resource: string, params: GetListParams) => {
    const { page, perPage } = params.pagination;
    const { field, order } = params.sort;

    const perPagevalue = perPage;

    const rangeStart = (page - 1) * perPagevalue;
    const rangeEnd = page * perPagevalue - 1;

    const defaultSort: IGetResourceParams = {
      sort: ['id', 'ASC'],
      range: [rangeStart, rangeEnd],
      filter: {},
    };

    const paramsDto: IGetResourceParams = {
      sort: [field, order],
      range: [rangeStart, rangeEnd],
    };

    paramsDto.filter = params.filter;

    if (resource === 'companies') {
      const data = await companiesService.getList(params);

      const companies = data?.data.map((el) => ({ ...el, id: el.uuid }));
      companiesStore.currentCompanies = companies;

      return {
        data: companies,
        total: data?.total,
      };
    }

    if (
      JSON.stringify(defaultSort) === JSON.stringify(paramsDto) &&
      resource !== 'saved-contacts'
    ) {
      contactsStore.setTotal(0);
      const total = contactsStore.total;

      return {
        data: [],
        total,
      } as GetListResult;
    }

    try {
      const response = await adminService.getResources(resource, paramsDto);
      const serializedData = response.data.map((contact) => serializeContact(contact));
      const contentRange: string = response.headers['content-range']?.replace(/.*?(\d+)$/, '$1');

      if (contentRange) contactsStore.setTotal(parseInt(contentRange, 10));
      let total = contactsStore.total;

      switch (resource) {
        case 'saved-contacts':
          contactsStore.savedContacts = serializedData;
          total = serializedData.length;
          break;
        case 'contacts':
          contactsStore.currentContacts = serializedData.map((obj) => {
            const equal = contactsStore.getSavedById(obj.id);

            return equal ?? obj;
          });
          break;
        default:
          contactsStore.currentContacts = [];
          contactsStore.savedContacts = [];
          break;
      }

      return {
        data: serializedData,
        total,
      } as GetListResult;
    } catch (e: unknown) {
      return {
        data: [],
        total: 0,
      } as GetListResult;
    }
  },

  getOne: async (resource, params) => {
    if (resource === 'companies') {
      const { id } = params;
      const url = companiesStore.currentCompanies?.find((el) => el.id === id)?.homepage_url ?? '';
      const data = await companiesService.getOne(url);
      return { data: data?.fullInfo ?? null } as GetOneResult;
    }
    const { id } = params;
    const { data } = await adminService.getResourceById(resource, id.toString());
    const serializedData = serializeContact(data);
    return {
      data: serializedData,
    } as GetOneResult;
  },

  create: async (resource, params) => {
    const { data } = params;
    const response = await adminService.createResource(resource, data);
    return {
      data: response.data,
    };
  },

  getMany: async () => {
    return {
      data: [{ full_name: 'get many' }],
    } as GetManyResult;
  },

  getManyReference: async () => {
    return {
      data: ['get many reference'],
    } as GetManyReferenceResult;
  },

  delete: async () => {
    return {
      data: { full_name: 'delete' },
    } as DeleteResult;
  },

  deleteMany: async () => {
    return {};
  },

  updateMany: async () => {
    return {};
  },

  update: async () => {
    return {
      data: { full_name: 'update' },
    } as UpdateResult;
  },
} as DataProvider;
