import CompanyButton from '@features/companies/buttons/company-button';
import ContactsButton from '@features/contacts/buttons/contacts-button';
import { Stack } from '@mui/material';

interface IChangeTableProps {
  selected: 'contacts' | 'companies';
}

const ChangeTableGroup = ({ selected }: IChangeTableProps) => {
  return (
    <Stack direction="row" alignItems="center" spacing={1} pb={24}>
      <CompanyButton selected={selected === 'companies'} />
      <ContactsButton selected={selected === 'contacts'} />
    </Stack>
  );
};

export default ChangeTableGroup;
