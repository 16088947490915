import BusinessIcon from '@mui/icons-material/Business';
import { Button, Typography } from '@mui/material';
import { AppRoutes } from '@shared/interfaces';
import { Link } from 'react-admin';
interface ICompanyButtonProps {
  selected: boolean;
}

const CompanyButton = ({ selected }: ICompanyButtonProps) => {
  return (
    <Button
      variant={selected ? 'contained' : 'outlined'}
      color="secondary"
      startIcon={<BusinessIcon />}
      sx={{
        px: 16,
        py: 4,
        height: 32,
        borderColor: selected ? 'none' : 'customGray1.light',
        color: selected ? 'primary.main' : 'text.primary',
        borderRadius: (theme) => theme.spacing(26),
      }}
    >
      <Link to={AppRoutes.Companies}>
        <Typography variant="subtitle1" color={selected ? 'primary.main' : 'text.primary'}>
          Company
        </Typography>
      </Link>
    </Button>
  );
};

export default CompanyButton;
