import {
  iFullCompanyResponse,
  ISimpleCompanyResponse,
} from '@shared/api/services/company-service/company.interfaces';
import { AxiosInstance } from 'axios';
import { GetListParams } from 'react-admin';

import withAuthorization from '../../withAuth';

class CompaniesService {
  authRequest: AxiosInstance;

  constructor(authRequest: AxiosInstance) {
    this.authRequest = authRequest;
  }

  async getList(params: GetListParams) {
    const queryParams = new URLSearchParams();
    queryParams.append('page', params.pagination.page.toString());
    queryParams.append('limit', params.pagination.perPage.toString());
    const { data } = await this.authRequest.get<ISimpleCompanyResponse>(
      `companies/filter?${queryParams.toString()}`
    );

    return data;
  }

  async getOne(url: string) {
    if (!url) {
      return null;
    }

    await Promise.allSettled([
      this.authRequest.post(`scrap/facebook/company/offline`, { url }),
      this.authRequest.post(`scrap/company/offline`, { url }),
    ]);

    const { data } = await this.authRequest.post<iFullCompanyResponse>(`companies/get-full-info`, {
      url,
    });

    return data;
  }

  // async exportSelectedContacts(contacts: string[]): Promise<string> {
  //   const { data } = await this.authRequest.post<string>(`export`, { ids: contacts });
  //   return data;
  // }
}

export const companiesService = new CompaniesService(withAuthorization);
