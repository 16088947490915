import { NoResults } from '@features/no-results/no-results';
import { Checkbox, Stack, Tooltip, Typography } from '@mui/material';
import { IContact, IEmail } from '@shared/api/services/contact-service';
import { observer } from 'mobx-react-lite';
import { forwardRef, ReactNode, useEffect, useState } from 'react';
import { Datagrid, FunctionField, useListController } from 'react-admin';

import ExportCheckboxField from './fields/export-checkbox-field';
import FullNameCompanyField from './fields/fullname-field';
import { IContactDataGrid } from './interfaces';
import useContactsStore, { contactsStore } from './model/contact-table.store';
import { styles } from './style';
import ContactsSkeleton from './UI/contacts-skeleton';

const ContactDataGrid = forwardRef<HTMLTableElement | null, IContactDataGrid>((props, ref) => {
  const {
    buttons: { PhoneButton, EmailButton },
  } = props;

  const { isFetching, isLoading } = useListController();

  const contactsStore = useContactsStore();

  const onRowClickHandler = (record: IContact) => {
    contactsStore.setCurrentContact(record);
    return '';
  };

  if (isFetching || isLoading) {
    return <ContactsSkeleton />;
  }

  return (
    <Datagrid
      sx={styles}
      bulkActionButtons={false}
      rowClick={(_, _r, record) => onRowClickHandler(record as IContact)}
      stickyHeader={false}
      empty={<NoResults />}
      ref={ref}
    >
      <ExportCheckboxField
        label={<CheckboxHeader />}
        sortable={false}
        cellClassName="export-checkbox"
      />
      <FullNameCompanyField
        source="full_name"
        label="Full name"
        sortable={false}
        cellClassName="full-name"
      />
      <FunctionField
        source="job_title"
        label="Job title"
        cellClassName="job-title"
        sortable={false}
        render={(record: Record<string, ReactNode> | undefined) => (
          <Stack direction="column" width="100%">
            <Tooltip title={record?.job_title}>
              <Typography
                variant="subtitle2"
                textOverflow="ellipsis"
                maxWidth="100%"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                {record?.job_title}
              </Typography>
            </Tooltip>
          </Stack>
        )}
      />
      <FunctionField
        label="Contacts"
        cellClassName="contacts"
        render={(
          record:
            | {
                id?: string;
                isTable?: boolean | undefined;
                openedEmails?: IEmail[] | undefined;
                openedPhones?: string[] | undefined;
                emailIsOpen?: boolean | undefined;
                phoneIsOpen?: boolean | undefined;
              }
            | undefined
        ) => (
          <Stack direction="row" gap={8} alignItems="center">
            <EmailButton
              contactId={record?.id || ''}
              isTable={true}
              openedEmails={record?.openedEmails ?? []}
              isEmailOpened={record?.emailIsOpen}
            />
            <PhoneButton
              contactId={record?.id || ''}
              isTable={true}
              openedPhones={record?.phoneIsOpen ? ([] as string[]) : undefined}
              isPhoneOpened={record?.phoneIsOpen}
            />
          </Stack>
        )}
      />
      <FullNameCompanyField label="Company" cellClassName="company" isCompany />
      <FunctionField
        label="Industry"
        cellClassName="industry"
        render={(record: Record<string, ReactNode> | undefined) => (
          <Stack direction="column" width="100%">
            <Tooltip title={record?.industry}>
              <Typography
                variant="subtitle2"
                textOverflow="ellipsis"
                maxWidth="100%"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                {record?.industry}
              </Typography>
            </Tooltip>
          </Stack>
        )}
      />
      <FunctionField
        label="Location"
        cellClassName="location"
        render={(record: Record<string, ReactNode> | undefined) => (
          <Stack direction="column" width="100%">
            <Tooltip title={record?.country}>
              <Typography
                variant="subtitle2"
                textOverflow="ellipsis"
                maxWidth="100%"
                overflow="hidden"
                whiteSpace="nowrap"
              >
                {record?.country}
              </Typography>
            </Tooltip>
          </Stack>
        )}
      />
    </Datagrid>
  );
});

export default observer(ContactDataGrid);

const CheckboxHeader = observer(function () {
  const [checked, setChecked] = useState(false);

  const handleSelectAll = () => {
    const reverseChecked = !checked;
    contactsStore.toggleSelectAllForExport(reverseChecked);
    setChecked(reverseChecked);
  };

  useEffect(() => {
    return () => contactsStore.resetAllExports();
  }, []);

  return (
    <Stack direction="row" justifyContent="center" alignItems="center">
      <Checkbox
        size="small"
        checked={checked}
        onChange={() => handleSelectAll()}
        disabled={!contactsStore.currentContacts}
      />
    </Stack>
  );
});
