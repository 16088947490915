import { Box, Link, Stack, Typography } from '@mui/material';
import { IFullCompany } from '@shared/api/services/company-service';
import { capitalizeWordsInString } from '@shared/libs';
import parse from 'html-react-parser';

export const CurrentCompany = ({ company }: { company: IFullCompany }) => {
  const { industry, employees, specialties, funding, stock, founded, about, address } = company;

  if (!company) return <></>;
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      border="1px solid #E7E8EF"
      borderRadius="8px"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={16}
        pt={12}
        pb={12}
      >
        <Typography variant="h4" fontWeight={700}>
          Overview
        </Typography>
      </Stack>
      <Stack
        width="100%"
        direction="column"
        justifyContent="space-between"
        gap={8}
        px={16}
        py={8}
        borderTop="1px solid #E7E8EF"
      >
        <Stack width="100%" direction="column" gap={16} borderBottom="1px solid #E7E8EF">
          <Stack gap={4}>
            <Typography variant="subtitle2" color="customGray2.dark">
              Description
            </Typography>
            <Typography variant="subtitle2" color="text.primary" textAlign="justify">
              {about ? parse(about.replace(/\n\n+/g, '\n\n')) : 'Unknown'}
            </Typography>
          </Stack>
        </Stack>

        <Stack gap={4} borderBottom="1px solid #E7E8EF">
          <Typography variant="subtitle2" color="customGray2.dark">
            Keywords
          </Typography>
          <Stack direction="row" alignItems="center" justifyContent="left" gap={8} flexWrap="wrap">
            {specialties?.length
              ? specialties.map((key) => (
                  <Box
                    key={key}
                    sx={{
                      whiteSpace: 'nowrap',
                      backgroundColor: '#F5F6F9',
                      paddingY: 2,
                      paddingX: 8,
                      borderRadius: 4,
                    }}
                  >
                    <Typography variant="subtitle2" color="customGray2.dark" fontWeight={500}>
                      {key}
                    </Typography>
                  </Box>
                ))
              : 'There are no keywords'}
          </Stack>
        </Stack>

        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          borderBottom="1px solid #E7E8EF"
        >
          <Typography variant="subtitle2" color="customGray2.dark">
            Size
          </Typography>
          <Typography variant="subtitle1" color="text.primary">
            {employees ?? 'Unknown'}
          </Typography>
        </Stack>

        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          borderBottom="1px solid #E7E8EF"
          pb={8}
        >
          <Typography variant="subtitle2" color="customGray2.dark">
            Industry
          </Typography>
          <Typography variant="subtitle1" color="text.primary">
            {industry ?? 'Unknown'}
          </Typography>
        </Stack>

        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          borderBottom="1px solid #E7E8EF"
        >
          <Typography variant="subtitle2" color="customGray2.dark">
            Last Round Funding
          </Typography>
          <Typography variant="subtitle1" color="text.primary">
            {funding?.financeMoney ?? 'Unknown'}
          </Typography>
        </Stack>

        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          borderBottom="1px solid #E7E8EF"
        >
          <Typography variant="subtitle2" color="customGray2.dark">
            Stock Price
          </Typography>
          <Typography variant="subtitle1" color="text.primary">
            {stock?.price ?? 'Unknown'}
          </Typography>
        </Stack>

        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          borderBottom="1px solid #E7E8EF"
        >
          <Typography variant="subtitle2" color="customGray2.dark">
            Founded year
          </Typography>
          <Typography variant="subtitle1" color="text.primary">
            {(typeof founded === 'number' ? founded : founded?.year) ?? 'Unknown'}
          </Typography>
        </Stack>

        <Stack width="100%" direction="column" justifyContent="space-between">
          <Typography variant="subtitle2" color="customGray2.dark">
            Address
          </Typography>
          <Typography variant="subtitle1" color="text.primary">
            {address ? (
              <Link href={`https://maps.google.com/?q=${address}`} target="_blank" rel="noreferrer">
                {capitalizeWordsInString(address)}
              </Link>
            ) : (
              'Unknown'
            )}
          </Typography>
        </Stack>
      </Stack>
    </Stack>
  );
};
