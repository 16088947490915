import { IFullCompany, ISimpleCompanyId } from '@shared/api/services/company-service';
import { IContact } from '@shared/api/services/contact-service';
import { makeAutoObservable } from 'mobx';
import { createContext, useContext } from 'react';

class CompaniesStore {
  currentCompanies: ISimpleCompanyId[] | null = null;
  currentCompany: IFullCompany | null = null;

  currentContact: IContact | null = null;
  exportContacts: string[] = [];
  currentContacts: IContact[] = [];
  exportAllSelector = false;

  total = 0;

  constructor() {
    makeAutoObservable(this);
  }

  isInExportQueue(id: string) {
    return this.exportContacts.find((contact) => contact === id) ? true : false;
  }

  toggleContactForExport(id: string) {
    if (id) {
      this.isInExportQueue(id)
        ? (this.exportContacts = this.exportContacts?.filter((contact) => contact !== id))
        : this.exportContacts?.push(id);
    }
    if (companiesStore.exportContacts.length < companiesStore.currentContacts.length) {
      this.exportAllSelector = false;
    } else {
      this.exportAllSelector = true;
    }
  }

  toggleSelectAllForExport() {
    this.exportAllSelector
      ? (this.exportContacts = this.currentContacts.map((contact) => contact.id))
      : (this.exportContacts = []);
  }

  setCurrentCompany(company: IFullCompany | null) {
    this.currentCompany = company;
  }
}

const companiesStore = new CompaniesStore();

const Context = createContext(companiesStore);

const useCompaniesStore = () => useContext(Context);

export default useCompaniesStore;

export { companiesStore };
