import ExportedContactsPopup from '@features/contacts/exported-contacts-popup/exported-contacts-popup';
import { Button, CircularProgress, Menu, MenuItem } from '@mui/material';
import { contactService, IExportedContactsResult } from '@shared/api/services/contact-service';
import { integrationService } from '@shared/api/services/integration-service/integration.service';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';

import { contactsStore, useContactsStore } from '../../../entities/contact-table';
import { ReactComponent as ExportIcon } from '../../../shared/assets/icons/Icon_Export.svg';

const ExportButton = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [fetching, setFetching] = useState(false);
  const [exportedLink, setExportedLink] = useState('');
  const [exportedContacts, setExportedContacts] = useState<IExportedContactsResult | null>(null);
  const contactStore = useContactsStore();
  const notify = useNotify();

  const handleExport = async () => {
    try {
      if (!contactsStore.exportContacts.length) {
        notify('You have not selected any contact', { type: 'warning' });
        return;
      }

      setFetching(true);
      const data = await contactService.exportSelectedContacts(contactStore.exportContacts);
      setExportedLink(data.file ?? '');
      setExportedContacts(data.response ?? null);
    } catch (e) {
      notify(`${e}`, { type: 'error' });

      setFetching(false);
    }
  };

  const handleExportCrm = async (type: 'salesforce' | 'hubspot') => {
    try {
      if (!contactsStore.exportContacts.length) {
        notify('You have not selected any contact', { type: 'warning' });
        return;
      }

      setFetching(true);
      setExportedContacts(
        await integrationService.importCRM(type, { contscts: contactStore.exportContacts })
      );
    } catch (e) {
      console.log(e);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const response = e?.response?.data;
      if (response?.stringCode?.includes('integration_token_not_found')) {
        notify(`You are not logged in into integration system`, { type: 'error' });
      } else {
        notify(`${e}`, { type: 'error' });
      }
    } finally {
      setFetching(false);
      handleClose();
    }
  };

  const downloadExported = async () => {
    if (exportedLink) {
      const currentDate = new Date();
      const formattedDate = currentDate.toISOString().slice(0, 10);
      const fileName = `export_${formattedDate}.csv`;
      const link = document.createElement('a');
      link.href = exportedLink;
      link.download = fileName;
      link.click();
      setExportedLink('');
      setExportedContacts(null);
      setFetching(false);
    }
  };
  useEffect(() => {
    downloadExported();
    // eslint-disable-next-line
  }, [exportedLink]);

  return (
    <div>
      <ExportedContactsPopup exportedContacts={exportedContacts} />
      <Button
        variant="contained"
        color="secondary"
        startIcon={fetching ? <CircularProgress size={18} /> : <ExportIcon />}
        sx={{
          py: 4,
          px: 16,
          height: 32,
          borderRadius: (theme) => theme.spacing(6),
        }}
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      >
        Export
      </Button>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose();
            handleExport();
          }}
        >
          Export to CSV
        </MenuItem>
        <MenuItem onClick={() => handleExportCrm('salesforce')}>Export to SalesForce</MenuItem>
        <MenuItem onClick={() => handleExportCrm('hubspot')}>Export to Hubspot</MenuItem>
      </Menu>
      {/*<a*/}
      {/*  ref={downloadRef}*/}
      {/*  href={exportedLink}*/}
      {/*  download={`bruxt-export-${contactsStore.exportContacts.length}-contacts`}*/}
      {/*/>*/}
    </div>
  );
};

export default observer(ExportButton);
