import { EmailCompanyInProfile } from '@features/companies/buttons/email-button';
import { PhoneCompanyInProfile } from '@features/companies/buttons/phone-button';
import { Avatar, capitalize, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { IContactPoint, IFullCompany } from '@shared/api/services/company-service';
import { ReactComponent as FacebookIcon } from '@shared/assets/icons/iconFacebook.svg';
import { ReactComponent as LinkIcon } from '@shared/assets/icons/iconLink.svg';
import { ReactComponent as LinkedinIcon } from '@shared/assets/icons/iconLinkedin.svg';
import { ReactComponent as TwitterIcon } from '@shared/assets/icons/iconTwitter.svg';
import { ReactComponent as AskFmIcon } from '@shared/assets/icons/links/askfm.svg';
import { ReactComponent as GithubIcon } from '@shared/assets/icons/links/github.svg';
import { ReactComponent as InstagramIcon } from '@shared/assets/icons/links/instagram.svg';
import { ReactComponent as KakaoTalkIcon } from '@shared/assets/icons/links/kakaotalk.svg';
import { ReactComponent as KikIcon } from '@shared/assets/icons/links/kik.svg';
import { ReactComponent as LineIcon } from '@shared/assets/icons/links/line.svg';
import { ReactComponent as MediumIcon } from '@shared/assets/icons/links/medium.svg';
import { ReactComponent as OculusIcon } from '@shared/assets/icons/links/oculus.svg';
import { ReactComponent as OkIcon } from '@shared/assets/icons/links/ok.svg';
import { ReactComponent as PinterestIcon } from '@shared/assets/icons/links/pinterest.svg';
import { ReactComponent as QQIcon } from '@shared/assets/icons/links/qq.svg';
import { ReactComponent as SkypeIcon } from '@shared/assets/icons/links/skype.svg';
import { ReactComponent as SnapchatIcon } from '@shared/assets/icons/links/snapchat.svg';
import { ReactComponent as SoundCloudIcon } from '@shared/assets/icons/links/soundcloud.svg';
import { ReactComponent as SpotifyIcon } from '@shared/assets/icons/links/spotify.svg';
import { ReactComponent as TiktokIcon } from '@shared/assets/icons/links/tiktok.svg';
import { ReactComponent as TumblrIcon } from '@shared/assets/icons/links/tumblr.svg';
import { ReactComponent as TwitchIcon } from '@shared/assets/icons/links/twitch.svg';
import { ReactComponent as VkIcon } from '@shared/assets/icons/links/vk.svg';
import { ReactComponent as WechatIcon } from '@shared/assets/icons/links/wechat.svg';
import { ReactComponent as WhatsappIcon } from '@shared/assets/icons/links/whatsapp.svg';
import { ReactComponent as WikipediaIcon } from '@shared/assets/icons/links/wikipedia.svg';
import { ReactComponent as YoutubeIcon } from '@shared/assets/icons/links/youtube.svg';
import { copyInBuffer, getValidLink } from '@shared/libs/validations';
import { parsePhoneNumberWithError } from 'libphonenumber-js';
import { FC, FunctionComponent, SVGProps, useCallback, useMemo } from 'react';
import { useNotify } from 'react-admin';

const getNumber = (phone: string) => {
  let number: string | undefined;
  try {
    number = parsePhoneNumberWithError(phone ?? '', { extract: true }).number.toString();
  } catch (e) {
    /* empty */
  }

  return number;
};

const getContactLabel = (el: IContactPoint, defaultValue: string) => {
  let answer = '';
  if (el?.contactType) {
    answer = capitalize(el?.contactType);
  }
  if (el?.areaServed) {
    answer += `(${el.areaServed})`;
  }

  return answer ?? defaultValue;
};

export const LeftSide: FC<{ company: IFullCompany }> = ({ company }) => {
  const notify = useNotify();

  const onCopyIconHandler = useCallback(
    (value: string) => {
      const isCopied = copyInBuffer(value);
      if (!isCopied) return;
      notify('Phone is copied', { type: 'success' });
    },
    [notify]
  );

  const phones = useMemo(() => {
    if (!(company?.phone || company?.contactPoint?.length)) {
      return [];
    }
    let answer: [string | null | undefined, string][] = [[company.phone, 'Phone']];
    if (company?.contactPoint?.length) {
      answer = [
        ...answer,
        ...company.contactPoint.map(
          (el: IContactPoint) =>
            [getNumber(el.telephone ?? ''), getContactLabel(el, 'Phone')] as [
              string | null | undefined,
              string,
            ]
        ),
      ];
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const filteredAnswer: [string, string][] = answer.filter((el) => Boolean(el[0]));
    return (
      filteredAnswer.map(([phone, title]) => (
        <Tooltip title={title} key={phone}>
          <PhoneCompanyInProfile phone={phone} onCopyPhone={onCopyIconHandler} title={title} />
        </Tooltip>
      )) ?? []
    );
  }, [company.contactPoint, company.phone, onCopyIconHandler]);

  const emails = useMemo(() => {
    if (!(company?.email || company?.contactPoint?.length)) {
      return [];
    }
    let answer: [string | null | undefined, string][] = [[company?.email, 'Email']];
    if (company?.contactPoint?.length) {
      answer = [
        ...answer,
        ...company.contactPoint.map(
          (el) => [el.email, getContactLabel(el, 'Email')] as [string | null | undefined, string]
        ),
      ];
    }

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const filteredAnswer: [string, string][] = answer.filter((el) => Boolean(el[0]));
    return filteredAnswer.map(([email, title]) => (
      <Tooltip title={title} key={email}>
        <EmailCompanyInProfile email={email} title={title} onCopyEmail={onCopyIconHandler} />
      </Tooltip>
    ));
  }, [company.contactPoint, company?.email, onCopyIconHandler]);

  const links = useMemo(() => {
    const processedLinks: Array<[string, FunctionComponent<SVGProps<SVGSVGElement>>]> = [];
    if (company?.webSite) {
      processedLinks.push([company?.webSite, LinkIcon]);
    }
    if (company?.linkedinLink) {
      processedLinks.push([company?.linkedinLink, LinkedinIcon]);
    }
    if (company?.twitterLink || company?.xLink) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      processedLinks.push([company.twitterLink || company.xLink, TwitterIcon]);
    }
    if (company?.facebookLink) {
      processedLinks.push([company?.facebookLink, FacebookIcon]);
    }
    if (company?.instagrammLink) {
      processedLinks.push([company?.instagrammLink, InstagramIcon]);
    }
    if (company?.askfmLink) {
      processedLinks.push([company?.askfmLink, AskFmIcon]);
    }
    if (company?.githubLink) {
      processedLinks.push([company?.githubLink, GithubIcon]);
    }
    if (company?.kakaotalkLink) {
      processedLinks.push([company?.kakaotalkLink, KakaoTalkIcon]);
    }
    if (company?.kikLink) {
      processedLinks.push([company?.kikLink, KikIcon]);
    }
    if (company?.lineLink) {
      processedLinks.push([company?.lineLink, LineIcon]);
    }
    if (company?.mediumLink) {
      processedLinks.push([company?.mediumLink, MediumIcon]);
    }
    if (company?.oculusLink) {
      processedLinks.push([company?.oculusLink, OculusIcon]);
    }
    if (company?.okLink) {
      processedLinks.push([company?.okLink, OkIcon]);
    }
    if (company?.pinterestLink) {
      processedLinks.push([company?.pinterestLink, PinterestIcon]);
    }
    if (company?.qqLink) {
      processedLinks.push([company?.qqLink, QQIcon]);
    }
    if (company?.skypeLink) {
      processedLinks.push([company?.skypeLink, SkypeIcon]);
    }
    if (company?.snapchatLink) {
      processedLinks.push([company?.snapchatLink, SnapchatIcon]);
    }
    if (company?.soundcloudLink) {
      processedLinks.push([company?.soundcloudLink, SoundCloudIcon]);
    }
    if (company?.spotifyLink) {
      processedLinks.push([company?.spotifyLink, SpotifyIcon]);
    }
    if (company?.tiktokLink) {
      processedLinks.push([company?.tiktokLink, TiktokIcon]);
    }
    if (company?.tumblrLink) {
      processedLinks.push([company?.tumblrLink, TumblrIcon]);
    }
    if (company?.twitchLink) {
      processedLinks.push([company?.twitchLink, TwitchIcon]);
    }
    if (company?.vkLink) {
      processedLinks.push([company?.vkLink, VkIcon]);
    }
    if (company?.wechatLink) {
      processedLinks.push([company?.wechatLink, WechatIcon]);
    }
    if (company?.whatsappLink) {
      processedLinks.push([company?.whatsappLink, WhatsappIcon]);
    }
    if (company?.wikipediaLink) {
      processedLinks.push([company?.wikipediaLink, WikipediaIcon]);
    }
    if (company?.youtubeLink) {
      processedLinks.push([company?.youtubeLink, YoutubeIcon]);
    }
    return processedLinks;
  }, [company]);

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      border={(theme) => `1px solid ${theme.palette.customGray1.light}`}
      borderRadius="8px"
      minWidth={460}
      maxWidth={600}
      width="100%"
    >
      <Stack direction="column" justifyContent="space-between" p={16}>
        <Stack direction="row" justifyContent="flex-start" alignItems="center">
          {company.logo_url && (
            <Avatar
              src={company.logo_url}
              alt={(company.fullName ?? '') + 'company logo'}
              sx={{ mr: 8, borderRadius: '25%', '& img': { objectFit: 'contain' } }}
            />
          )}
          <Typography
            variant="h3"
            color="text.primary"
            fontWeight={700}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            {company.fullName}
          </Typography>
        </Stack>
      </Stack>
      <Stack
        direction="column"
        width="100%"
        borderTop={(theme) => `1px solid ${theme.palette.customGray1.light}`}
        pt={12}
        px={16}
      >
        <Stack direction="row" mb={12}>
          {links.map(([link, Icon]) => (
            <Tooltip title={getValidLink(link)} key={link}>
              <a href={getValidLink(link)} rel="noreferrer" target="_blank">
                <IconButton sx={{ width: 40 }}>
                  <Icon />
                </IconButton>
              </a>
            </Tooltip>
          ))}
        </Stack>
        {emails.map((el) => el)}
        {phones.map((el) => el)}
      </Stack>
    </Stack>
  );
};
